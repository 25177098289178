@font-face {
  font-family: SilomBol;
  src: url("SilomBol.9f52f512.ttf");
}

body {
  height: 100vh;
  max-height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #171717;
  background-image: url("v1-Simple-synth-wave-style-background-4K.2c54aec2.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.mainsection {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6.44468vh 2.9vw 0 6.44468vh;
  display: flex;
}

.parent {
  height: 50vh;
  width: 50vw;
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  border-radius: 10px;
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(16, 1fr);
  padding: 2.5vh;
  display: grid;
}

.parent-bg {
  -webkit-backdrop-filter: blur(8.5px);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  background: #ffffff1a;
}

.title-img-loaded {
  width: 500px;
}

.container {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3vh;
  padding: 2.5vh;
  display: flex;
}

.animate {
  animation: .6s cubic-bezier(.39, .575, .565, 1) scale-up-center;
}

.grid-div {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  cursor: pointer;
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(8, 1fr);
  transition: all .4s ease-in-out;
  animation: .4s cubic-bezier(.39, .575, .565, 1) scale-up-center;
  display: grid;
  position: relative;
}

.purchasable-slot {
  height: 100%;
  width: 100%;
  background-color: #0006;
  justify-content: center;
  align-items: center;
  animation: .25s animateToUp;
  display: flex;
  position: absolute;
}

.uil-shopping-bag {
  color: #9700ff;
  font-size: 1.5rem;
}

.MyAlgohidden {
  display: none !important;
}

.grid-disabled {
  pointer-events: none;
}

#pick {
  display: none;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  z-index: 1;
  opacity: 0;
  background-color: #555;
  border-radius: 6px;
  margin-left: -60px;
  padding: 5px 0;
  transition: opacity .3s;
  position: absolute;
  bottom: 125%;
  left: 50%;
}

.tooltiptext2 {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  z-index: 4;
  opacity: 1;
  background-color: #555;
  border-radius: 6px;
  margin-left: -60px;
  padding: 5px 0;
  transition: opacity .3s;
  position: absolute;
  bottom: 90%;
  left: 50%;
}

.tooltiptext:after, .tooltiptext2:after {
  content: "";
  border: 5px solid #0000;
  border-top-color: #555;
  margin-left: -5px;
  position: absolute;
  top: 100%;
  left: 50%;
}

.MyAlgo:hover .tooltiptext, .MyAlgo2:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.MyAlgo:disabled, .MyAlgo2:disabled {
  opacity: .5;
  pointer-events: none;
  background-color: gray;
}

.colorPicker:hover .tooltiptext2 {
  visibility: hidden;
  opacity: 0;
}

.colorPickSelector {
  width: 36px;
  height: 36px;
  cursor: pointer;
  -o-transition: all linear .2s;
  border-radius: 5px;
  transition: all .2s linear;
}

.grid-div:hover {
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  z-index: 1;
  background-color: gray;
  transform: scale(1.5);
}

.EditState-hidden {
  display: none;
}

.selected-grid-container-hidden {
  display: none !important;
}

.selected-grid-container {
  height: 100%;
  color: #fff;
  width: 40%;
  -webkit-backdrop-filter: blur(8.5px);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  background: #ffffff1a;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px;
  display: flex;
}

.Details-heading {
  color: #9700ff;
  font-size: 5rem;
  font-weight: 400;
}

.detail-box {
  height: 30vh;
  flex-direction: column;
  flex: .6;
  justify-content: center;
  align-items: center;
  margin: 2.5vh;
  display: flex;
}

.box {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.history-container {
  width: 12%;
  height: 80%;
  background-color: #00000040;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 14px;
  display: flex;
}

.history-box {
  height: 25%;
  width: 100%;
  cursor: pointer;
}

.color-picker-img {
  height: 1.5rem;
  z-index: 1;
}

.selected-grid {
  height: 20vh;
  width: 60%;
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(8, 1fr);
  margin-bottom: 2.5vh;
  display: grid;
}

.img-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.connection-img {
  height: 3vh;
  margin-right: .5vw;
}

.pixels {
  height: auto;
  cursor: cell;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.pixels:hover {
  filter: brightness(80%);
}

.info-container {
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-bottom: 5vh;
  display: flex;
}

.picker {
  width: 100%;
  height: 100px !important;
}

.connect-grid {
  height: 100%;
}

.MyAlgo {
  color: #fff;
  cursor: pointer;
  background-color: #1e51ad;
  border: none;
  border-radius: 5px;
  outline: none;
  margin-top: 2.5vh;
  padding: 10px;
  font-family: Poppins;
  transition: all 1s;
}

.MyAlgo:hover {
  filter: brightness(80%);
}

.MyAlgo2 {
  color: #fff;
  cursor: pointer;
  background-color: #1e51ad;
  border: none;
  border-radius: 5px;
  outline: none;
  margin-top: 2.5vh;
  padding: 10px;
  font-family: Poppins;
  transition: all 1s;
}

.MyAlgo2:hover {
  filter: brightness(80%);
}

.EditState {
  margin-top: 1vh;
  margin-right: 6vw;
}

.EditLink {
  color: #f6f6f6;
  background-color: #00000040;
  border: 1px solid #111;
  border-radius: 5px;
  outline: none;
  margin-right: 6.5vw;
  padding: .6rem;
  font-family: Poppins, sans-serif;
}

#slot-link {
  color: #fff;
  margin-bottom: 1vh;
}

.EditLink::placeholder {
  color: #f6f6f6;
  opacity: .5;
}

input[text]:out-of-range {
  border-color: red;
}

.MyAlgoHidden, .MyAlgoHidden2 {
  display: none;
}

.pickers-container {
  height: 80%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 1vh;
  display: flex;
}

.colorPicker {
  height: 80%;
  width: 100%;
  cursor: pointer;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  outline: none;
  place-self: start;
  padding: 1vh;
  font-family: Poppins;
}

.color-input-picker {
  margin-bottom: 10vh;
}

#old-color {
  color: #fff;
  font-family: Poppins;
  text-decoration: underline;
}

.toast-title {
  font-weight: bold;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toast-message a, .toast-message label {
  color: #fff;
}

.toast-message a:hover {
  color: #ccc;
  text-decoration: none;
}

.toast-close-button {
  float: right;
  color: #fff;
  -webkit-text-shadow: 0 1px 0 #fff;
  text-shadow: 0 1px #fff;
  opacity: .8;
  -ms-filter: progid:DXImageTransform. Microsoft. Alpha(Opacity= 80);
  filter: alpha(opacity= 80);
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  position: relative;
  top: -.3em;
  right: -.3em;
}

.toast-close-button:hover, .toast-close-button:focus {
  color: #000;
  cursor: pointer;
  opacity: .4;
  filter: alpha(opacity= 40);
  text-decoration: none;
}

.rtl .toast-close-button {
  float: left;
  left: -.3em;
  right: .3em;
}

button.toast-close-button {
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
  border: 0;
  padding: 0;
}

.toast-top-center {
  width: 100%;
  top: 0;
  right: 0;
}

.toast-bottom-center {
  width: 100%;
  bottom: 0;
  right: 0;
}

.toast-top-full-width {
  width: 100%;
  top: 0;
  right: 0;
}

.toast-bottom-full-width {
  width: 100%;
  bottom: 0;
  right: 0;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  bottom: 12px;
  right: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  z-index: 999999;
  pointer-events: none;
  position: fixed;
}

#toast-container * {
  box-sizing: border-box;
}

#toast-container > div {
  pointer-events: auto;
  width: 300px;
  color: #fff;
  background-position: 15px;
  background-repeat: no-repeat;
  border-radius: 3px;
  margin: 0 0 6px;
  padding: 10px 10px 10px 50px;
  font-size: .9rem;
  position: relative;
  overflow: hidden;
}

#toast-container > div.rtl {
  direction: rtl;
  background-position: right 15px center;
  padding: 15px 50px 15px 15px;
}

#toast-container > div:hover {
  opacity: 1;
  -ms-filter: progid:DXImageTransform. Microsoft. Alpha(Opacity= 100);
  filter: alpha(opacity= 100);
  cursor: pointer;
  box-shadow: 0 0 12px #000;
}

#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}

#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

#toast-container.toast-top-center > div, #toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#toast-container.toast-top-full-width > div, #toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  -webkit-backdrop-filter: blur(8.5px);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  background: #ffffff1a;
  border-radius: 10px;
}

.toast-success, .toast-error, .toast-info, .toast-warning {
  -webkit-backdrop-filter: blur(8.5px);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  background: #ffffff1a;
  border-radius: 10px;
  color: #fdfdfd !important;
}

.toast-progress {
  height: 4px;
  opacity: .4;
  -ms-filter: progid:DXImageTransform. Microsoft. Alpha(Opacity= 40);
  filter: alpha(opacity= 40);
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
}

.bannerimg {
  height: 10vh;
}

.link-container {
  justify-content: center;
  align-items: center;
  margin-right: 2vw;
  display: flex;
}

.twitter-icon, .algogems-icon {
  margin-right: 1.5vw;
}

.rand-icon {
  background: #fff;
  border-radius: 20%;
}

.icon-link {
  height: 3vh;
  cursor: pointer;
  transition: all .2s;
}

.icon-link:hover {
  transform: scale(1.15);
}

.banner-img {
  height: 100%;
  -webkit-backdrop-filter: blur(8.5px);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  background: #ffffff1a;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2vh;
  display: flex;
}

.hidden {
  display: none;
}

.switch-container {
  cursor: pointer;
  width: 40%;
  align-self: flex-start;
  position: relative;
}

.picker-container {
  cursor: pointer;
  margin-top: 1vh;
  position: relative;
}

.picker-tooltip {
  visibility: hidden;
  width: 100%;
  color: #fff;
  text-align: center;
  width: 120px;
  z-index: 1;
  background-color: #00000040;
  border-radius: 6px;
  margin-left: -40px;
  padding: 5px 0;
  font-size: .8rem;
  position: absolute;
  bottom: 100%;
  left: 0%;
}

.picker-container:hover .picker-tooltip {
  visibility: visible;
}

.uil-paint-tool, .uil-times {
  background-color: #ffffff40;
  border-radius: 50%;
  margin-bottom: 10vh;
  padding: .1rem .3rem;
}

.tooltip {
  visibility: hidden;
  width: 100%;
  color: #fff;
  text-align: center;
  width: 120px;
  white-space: nowrap;
  z-index: 1;
  background-color: #00000040;
  border-radius: 6px;
  margin-left: -60px;
  padding: 5px 0;
  font-size: .8rem;
  position: absolute;
  bottom: 100%;
  left: 10%;
}

.switch-container:hover .tooltip {
  visibility: visible;
}

.switch {
  width: 45px;
  height: 25px;
  display: inline-block;
  position: relative;
}

.slider {
  cursor: pointer;
  transform: .5s;
  background-color: #ccc;
  border-radius: 34px;
  position: absolute;
  inset: 0;
}

.switch input {
  display: none;
}

.slider:before {
  content: "";
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-radius: 50px;
  transition: all .2s;
  position: absolute;
  bottom: 4px;
  left: 3px;
}

input:checked + .slider {
  background-color: #9700ff;
}

input:checked + .slider:before {
  transform: translate(20px);
}

@media (width <= 240px) {
  #toast-container > div {
    width: 11em;
    padding: 8px 8px 8px 50px;
  }

  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }

  #toast-container .toast-close-button {
    top: -.2em;
    right: -.2em;
  }

  #toast-container .rtl .toast-close-button {
    left: -.2em;
    right: .2em;
  }
}

@media (width >= 241px) and (width <= 480px) {
  #toast-container > div {
    width: 18em;
    padding: 8px 8px 8px 50px;
  }

  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }

  #toast-container .toast-close-button {
    top: -.2em;
    right: -.2em;
  }

  #toast-container .rtl .toast-close-button {
    left: -.2em;
    right: .2em;
  }
}

@media (width >= 481px) and (width <= 768px) {
  .picker-container {
    width: 70%;
  }

  #toast-container > div {
    width: 25em;
    padding: 15px 15px 15px 50px;
  }

  #toast-container > div.rtl {
    padding: 15px 50px 15px 15px;
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (width <= 1440px) {
  .history-container {
    width: 10%;
    height: 80%;
    margin-top: 6px;
    margin-left: 15px;
  }

  .colorPicker {
    text-align: center;
    margin-top: .5vh;
    font-size: .5rem;
  }

  .selected-grid-container {
    width: 40%;
    height: 300px;
  }

  .selected-grid {
    width: 40%;
    height: 17vh;
  }

  .detail-box {
    width: 80%;
  }

  .EditLink {
    margin-right: 0;
  }
}

@media screen and (width <= 1024px) {
  .selected-grid {
    width: 80%;
    height: 24vh;
  }

  .selected-grid-container {
    width: 60%;
    height: 300px;
  }

  .picker-container {
    width: 60%;
  }

  .history-container {
    width: 25%;
    height: 80%;
    margin-left: 15px;
  }

  .history-box {
    height: 25%;
    width: 80%;
  }
}

@media screen and (width <= 768px) {
  .selected-grid-container {
    width: 30%;
    height: 100%;
    flex-direction: column;
  }

  .detail-box {
    height: 20vh;
  }

  .container {
    width: 60%;
  }

  .parent {
    width: 100%;
    height: 35vh;
  }

  .selected-grid {
    height: 15vh;
    width: 100%;
  }

  .parent {
    height: 30vh;
  }

  .bannerimg {
    height: 7vh;
    margin-bottom: .5vh;
  }

  .mainsection {
    padding: 6.44468vh 0;
  }

  .box {
    flex-direction: column;
  }

  .picker-container {
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
    display: flex;
  }

  .colorPicker {
    width: 100%;
    max-width: 100% !important;
  }

  .EditLink {
    width: 90%;
    margin-top: 1vh;
    margin-right: 0;
  }

  .EditState {
    margin-right: 0;
  }
}

@media screen and (width <= 480px) {
  .box {
    align-items: center;
  }

  body {
    height: 155vh;
  }

  .selected-grid-container {
    width: 90%;
    height: 100%;
    flex-direction: column;
    padding: 0 .25rem;
  }

  .pickers-container {
    width: 100%;
  }

  .colorPicker {
    width: 100%;
    height: 4vh;
    align-self: center;
    margin-bottom: 2vh;
    max-width: 100% !important;
  }

  .history-container {
    width: 100%;
  }

  .container {
    width: 65%;
  }

  .parent {
    width: 100%;
    height: 28vh;
  }

  .detail-box {
    height: max-content;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    display: flex;
  }

  .selected-grid {
    height: 20vh;
    width: 50%;
  }

  .bannerimg {
    height: 5vh;
    margin-bottom: .5vh;
  }

  .box {
    height: 100%;
    flex-direction: column;
  }

  .EditState {
    width: 50%;
    margin-top: 1vh;
    margin-right: 0;
  }

  .EditLink {
    margin-top: 1vh;
    margin-right: 0;
  }

  .history-container {
    height: 5vh;
    width: 100%;
    margin-bottom: 2vh;
    margin-right: 15px;
    padding: 0;
  }

  .history-box {
    height: 25%;
    width: 100%;
    cursor: pointer;
  }
}

.d-flex-row {
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

@media screen and (width <= 768px) {
  .d-flex-row {
    flex-direction: column;
  }
}

@keyframes animateToUp {
  from {
    opacity: 0;
    bottom: -50%;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}

/*# sourceMappingURL=index.2d4f7433.css.map */
